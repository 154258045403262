//import React, { useCallback } from "react";
import * as React from 'react';
import { useCallback } from 'react';
import 'survey-core/modern.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

//npm install jquery --save
import $ from 'jquery'

StylesManager.applyTheme("modern");

//see https://surveyjs.io/Documentation/Library?id=get-started-react#install-the-survey-react-npm-package

//if can't get the react function app working, try the example on:
//  https://github.com/surveyjs/surveyjs_react_quickstart
//      npm install survey-react
//      npm install survey-react-ui

var LINK_ID = '1FFECE35-0C72-4719-9CC2-AF79EB373E6E';   

//survey creator produces json, show that here
//  this could come from the db...
var surveyJson = {
    elements: [{
        name: "FIRST",
        title: "Enter your FIRST name:",
        type: "text"
    }, {
        name: "LastName",
        title: "Enter your last name:",
        type: "text"
    }]
};

// interface ISurveyDefinition {
//     createDate: string;
//     lastChanged: string;
//     surveyDetail: string;
//     surveyName: string;
//     createByUserID: string;
//     id: string;
//     lastChangedByID: string;
//     portalID: string;
//     scheduleID: string;
//     scheduleName: string;
//     repetition: number;
// }

// export class SurveyClass {
//     createDate: string | undefined;
//     lastChanged: string | undefined;
//     surveyDetail: string | undefined;
//     surveyName: string | undefined;
//     createByUserID: string | undefined;
//     id: string | undefined;
//     lastChangedByID: string | undefined;
//     portalID: string | undefined;
//     scheduleID: string | undefined;
//     scheduleName: string | undefined;
//     repetition: number | undefined;
// }

var _responseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_SAVERESPONSE;
function App() {
    //which survey are we showing???
    //const [searchParams, setSearchParams] = useSearchParams();
    //searchParams.get("__firebase_request_key")

    //TODO: this is quick and dirty, do this a better way
    //NOTE: this assumes surveydefinitionid is 1st in the query list
    var id = LINK_ID;

    const path = window.location.search;
    if (path.includes("id=")) {          
        id = path.substring(path.indexOf("=") + 1);
        if (id.indexOf("&") > 0) {
            id = id.substring(0, id.indexOf("&") - 1)
        }
    }

    LINK_ID = id;

    var ticketNumber: any = GetTicketNumber(id);

    //this runs synchronously
    var baseUrl = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETDEFINITIONDETAIL;
    var url =  buildUrlReplace(baseUrl, "{id}", id);
    var json = GetJsonResults(url);
        //"https://surveyfnapp-dev.azurewebsites.net/api/GetDefinitionDetail/" + id + "?code=kGI7Vc3QjDyloZQvwi_3hoe5n8_a-5-YVy23bU3ZUX9wAzFu6D-LdQ=="
        //"http://localhost:7071/api/GetDefinitionDetail/" + id

    var j = JSON.stringify(json);
    var k = JSON.parse(j);

    //return BuildSurvey(surveyJson);
    return BuildSurvey(k, ticketNumber);
}

function GetTicketNumber(id: any) {
    var url1: any = process.env.REACT_APP_SURVEY_FUNCTIONCODE_GETDEFINITIONDETAIL;
    var url2: any = url1.toString().replace("GetDefinitionDetail", "GetTicketNumber");
    var url3: any =  buildUrlReplace(url2, "{id}", id);
    var result: any = GetJsonResults(url3);
    return result;
}

function BuildSurvey(s: object, ticketNum: any) {
    //show the survey
    const survey = new Model(s);

    //set the value for the ticket# question
    try {
        if ((ticketNum != null) && (ticketNum != '') && (ticketNum != '0-0'))
            survey.setValue("TicketNumber", ticketNum);
        
        } 
    catch{}

    //this works on complete
    survey.onComplete.add((sender: { data: any; }, options: any) => {
        console.log("Complete! Response:" + JSON.stringify(sender.data));
        var result = {"surveyDefinitionID":LINK_ID,"responseDetail":sender.data};

        //how to save url in secrets/keyvault?

        PostJsonData(JSON.stringify(result));

    });
    //end on complete

    //render
    return <div>
            <Survey model={survey} />
           </div>;

}

function PostJsonData(json: string) {
    var result = "'"

    $.ajax({
        method: "POST",
        async: false,
        url: _responseUrl,
        data: json
    })
        .done(function (msg) {
            result = msg;
            return result;
        });

    return result;
}

function GetJsonResults(url: string) {
    var result = "'";
    $.ajax({
        method: "GET",
        async: false,
        url: url,
        dataType: "json"
    })
        .done(function (msg) {
            result = msg;
            return result;
        });

    return result;
}

function buildUrlReplace(url: any, searchFor: any, replaceWith: any) {
    var s = url;
        s = s.replace(searchFor, replaceWith)
    return s;
}

export default App;



